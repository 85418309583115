import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../utils/icons'
import './matterStream.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../reducers/reducerModel'
import { Blogs, BlogsCategory, News, NewsCategory } from '../../constants/model'
import homeActions from '../../actions/homeActions'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import ProgressiveImage from '../../components/ProgressiveImage'
import React from 'react'
import Images from '../../utils/images'
import { ActionTypes } from '../../constants'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useMediaQuery } from '@mui/material'
import { moengageEvent } from '../../utils/common'
import { round } from 'lodash'

const MatterStream = () => {
  const dispatch: Function = useDispatch()    
  const history = useHistory()  

  const { blogsCategories, newsCategories } = useSelector(
    (store: ReducerModel) => store.home
  )

  const isSmall = useMediaQuery('(min-width:576px)');

  useEffect(()=> {
    var start = new Date().getTime();
    dispatch({
      type: ActionTypes.NEWS,
      payload: {
        newsType: '',
      },
    })
    window.scrollTo(0, 0)
    //get all blogs
    dispatch(homeActions.getBlogs())    
    //get all news
    dispatch(homeActions.getNews())      
    //get all news
    dispatch(homeActions.getAwards())     
    //get all leaders
    dispatch(homeActions.getWhoWeAreLeaders())
    //get all press releases
    dispatch(homeActions.getPressReleases())   
    //get all leadership
    dispatch(homeActions.getLeadership())
    //get all logos
    dispatch(homeActions.getLogoAndTrademark())
    //get all aera 500
    dispatch(homeActions.getAera5000())
    //get all aera 500 plus
    dispatch(homeActions.getAera5000Plus())
    //get product portfolio
    dispatch(homeActions.getProductPortfolio())

    return () => {
      var elapsed = new Date().getTime() - start;      

      let scrollPercentage = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';

      let percent = (scrollPercentage[st]||b[st]) / ((scrollPercentage[sh]||b[sh]) - scrollPercentage.clientHeight) * 100;

      moengageEvent('MatterStream_Page_Viewed', 
        {
          'Page_Name': 'Matter Stream Page', 
          'Page_URL': window.location.href,
          'Source': document.referrer,
          'Duration': elapsed/1000,
          'Page_Scroll_Depth': round(percent)
        })
    }
  },[])      
  
  //filter category which contains blogs
  const blogsAllCategories = React.useMemo(() => {
    const latestBlogs: Blogs[] = []
    blogsCategories.map((blogs: BlogsCategory)=> {
      blogs.allblogs.map((blog: Blogs)=> {
        latestBlogs.push(blog)
      })
    })
    
    latestBlogs.sort((a,b) => {          
      return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
    })

    return latestBlogs
  }, [blogsCategories])

  //filter category which contains news
  const newsAllCategories = React.useMemo(() => {    
    const latestNews: News[] = []
    newsCategories.map((news: NewsCategory)=> {
      news.allnews.map((newsItem: News)=> {
        latestNews.push(newsItem)
      })
    })
    
    latestNews.sort((a,b) => {          
      return new Date(b.newsDate).getTime() - new Date(a.newsDate).getTime()
    })
    return latestNews
  }, [newsCategories])      
  const BlogUrl=(title:string)=>{
    let blogTitle = title.toLowerCase().replace(/ /g,"-").replace(/\?/g, '');
    let url='/blogs/' + blogTitle, target: '_blank';
    return url;
  };

  const openBlog = (title: string) => {
    let blogTitle = title.toLowerCase().replace(/ /g,"-").replace(/\?/g, '')
    window.open('/blogs/' + blogTitle, '_blank')    
  }
      
  const isMobile =useMediaQuery('(max-width:426px)');
  return (
    <div className="matter-stream">
      <Header />
      <h1 className="visuallyHidden">MATTER Stream</h1>      

      <div className="matter-stream-header">
        {/* <p className="title monument">MATTER Stream</p> */}
        <span className="title monument"> MATTER Stream</span>
        {isMobile?(<img className="matter-stream-img" src={Images.MatterStreamHeader_Mobile} alt="Matter Stream Header" />):
        (<img className="matter-stream-img" src={Images.MatterStreamHeader} alt="Matter Stream Header" />)
        }
      </div>  

      {/* blogs section */}
      <div className="matter-stream-content-blogs content-container">
        {/* {blogsAllCategories.map((blogCategory: BlogsCategory, index) => 
          blogCategory.id === 1 &&  */}
          <div className="blogs-section">
            <div className="category-title">
              <h5 className="category-sub-title black">                
                Blogs
              </h5>
              {(blogsAllCategories.length > 3 && isSmall) &&
                <p className="more-posts primary pointer underline" onClick={() => {
                    history.push('/blogs/category-blogs/')                    
                }}>
                  More Blogs             
                  <img
                    style={{ marginLeft: '2px' }}
                    src={Icons.RightChevronIconBlue}
                    alt="Right Chevron Icon Blue"
                  />
                </p>
              }
            </div>                                
            
            {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}}>
              {blogsAllCategories.slice(0,3).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {
                    moengageEvent(`Matter_Streams_Blog_${index+1}_Clicked`, 
                      {
                        'Page_URL': window.location.origin+`${BlogUrl(item.title)}`,
                        'Source': window.location.href
                      })                     
                    openBlog(item.title)              
                    //tagMethodGTM(blogCategory.categoryName, "Matter Blog " + blogCategory.categoryName, "Click", item.title)
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {
                    
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                    
                    <p className="date ml-8">{item.readTime} min read</p>
                    <p className="blog-data-title mt-8">
                      {item.title}
                    </p>                
                    <p className="blog-description">
                      {item.blogIntroduction} 
                    </p>
                  </div>
                  </Link>
                </Grid>
              )}               
            </Grid>
            :<>
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {blogsAllCategories.slice(0,3).map((item, index)=>     
                  <SwiperSlide>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      openBlog(item.title)              
                      //tagMethodGTM(blogCategory.categoryName, "Matter Blog " + blogCategory.categoryName, "Click", item.title)
                    }}>
                      <div className="blog-posts monument pointer" onClick={() => {
                        moengageEvent(`Matter_Streams_Blog_${index+1}_Clicked`, 
                          {
                            'Page_URL': window.location.origin+`${BlogUrl(item.title)}`,
                            'Source': window.location.href
                          }) 
                      }
                        }>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="more-posts-blog-img" 
                          imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                          previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                    
                        <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                        <span className="dot"></span>                    
                        <p className="date ml-8">{item.readTime} min read</p>
                        <p className="blog-data-title mt-8">
                          {item.title}
                        </p>                
                        <p className="blog-description">
                          {item.blogIntroduction} 
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                )}
              </Swiper>  
              {blogsAllCategories.length > 3 &&
                <p className="more-posts primary pointer underline" onClick={() => {
                    history.push('/blogs/category-blogs/')                    
                }}>
                  More Blogs             
                  <img
                    style={{ marginLeft: '2px' }}
                    src={Icons.RightChevronIconBlue}
                    alt="Right Chevron Icon Blue"
                  />
                </p>
              }    
              </>      
            }
            
          </div>
        {/* )}         */}
      </div>  

      {/* category and news */}
      <div style={{ backgroundColor: '#f5f5f5', padding: '80px 0', margin: '80px 0 140px' }}>
      
        <div className="matter-stream-content-blogs content-container" style={{marginTop: '0'}}>
          {/* {newsAllCategories.map((newsCategory: NewsCategory, index) => 
            newsCategory.id === 1 &&  */}
            <div className="blogs-section">
              <div className="category-title">
                <h5 className="category-sub-title black">                
                  Newsroom
                </h5>
                {(newsAllCategories.length > 3 && isSmall) &&
                <div onClick={() => {
                  moengageEvent('Matter_Streams_Newsroom_More_News_Clicked',
                    {
                      'Page_URL': window.location.origin+'/matter-stream/news/',
                      'Source': window.location.origin+'/matter-stream'
                    })
                  }  }>
                    <p className="more-posts primary pointer underline" onClick={() => {
                      history.push('/matter-stream/news/')                    
                  }}>
                    More News             
                    <img
                      style={{ marginLeft: '2px' }}
                      src={Icons.RightChevronIconBlue}
                      alt="Right Chevron Icon Blue"
                    />
                  </p>                  
                </div>          
                }
              </div>            
              
              {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}}>
                {newsAllCategories.slice(0,3).map((item, index)=>                 
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {
                      
                      moengageEvent(`Matter_Streams_Newsroom_${index+1}_Clicked`, 
                        {
                          'Page_URL': `${item.newsURL}`,
                          'Source': window.location.href
                        })                    
                      window.open(item.newsURL)                    
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {
                                          
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.newsPublisher}</p> 
                      {/* <span className="dot ml-8"></span> */}
                      {/* <p className="date ml-8">{item.readTime} min read</p> */}
                      <p className="blog-data-title mt-8">
                        {item.newsTitle}
                      </p>
                    </div>
                    </Link>
                  </Grid>
                )}               
              </Grid> :<>
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {newsAllCategories.slice(0,3).map((item, index)=>                 
                  <SwiperSlide>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      window.open(item.newsURL)                    
                    }}>
                    <div className="blog-posts monument pointer" onClick={() => {
                          moengageEvent(`Matter_Streams_Newsroom_${index+1}_Clicked`, 
                            {
                              'Page_URL': `${item.newsURL}`,
                              'Source': window.location.href
                            })           
                    }
                      }>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="more-posts-blog-img" 
                        imgSrc={process.env.STRAPI_URL + item.newsImageWeb} 
                        previewSrc={process.env.STRAPI_URL + item.newsThumbNailImageWeb} />                    
                      <p className="date mr-8">{moment(item.newsDate).format('MMM DD, YYYY')}</p>
                      <span className="dot"></span>                     
                      <p className="date ml-8">{item.newsPublisher}</p> 
                      {/* <span className="dot ml-8"></span> */}
                      {/* <p className="date ml-8">{item.readTime} min read</p> */}
                      <p className="blog-data-title mt-8">
                        {item.newsTitle}
                      </p>
                    </div>
                    </Link>
                  </SwiperSlide>
                )}
              </Swiper>
              {newsAllCategories.length > 3 &&
              <div onClick={
                () => {
                  moengageEvent('Matter_Streams_Newsroom_More_News_Clicked',
                    {
                      'Page_URL': window.location.origin+'/matter-stream/news/',
                      'Source': window.location.origin+'/matter-stream'
                    })
                }

              }> 
                 <p className="more-posts primary pointer underline" onClick={() => {
                      history.push('/matter-stream/news/')                    
                  }}>
                    More News             
                    <img
                      style={{ marginLeft: '2px' }}
                      src={Icons.RightChevronIconBlue}
                      alt="Right Chevron Icon Blue"
                    />
                  </p>
              </div>              
                }
                </>
            } 
            </div>
          {/* )}         */}
        </div>

      </div>

      <Footer />
    </div>    
  )
}

export default MatterStream
