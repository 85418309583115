import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../../utils/icons'
import '../blogs.scss'
import { useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { InputAdornment, Select, useMediaQuery, MenuItem } from '@mui/material'
import moment from 'moment'
import { Blogs, BlogsCategory } from '../../../constants/model'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'
import { tagMethodGTM } from '../../../utils/common'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Swiper, SwiperSlide } from 'swiper/react'

const CategoryBlogs = () => {
  const { blogsCategories } = useSelector(
      (store: ReducerModel) => store.home
    )
  //const { id } = useParams()
  const history = useHistory()

  const [categoryName, setCategoryName] = useState('')

  //filter category which contains blogs
  const blogsAllCategories = React.useMemo(() => {
    const latestBlogs: Blogs[] = []
    blogsCategories.map((blogs: BlogsCategory)=> {
      blogs.allblogs.map((blog: Blogs)=> {
        latestBlogs.push(blog)
      })
    })
    
    latestBlogs.sort((a,b) => {          
      return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
    })

    return latestBlogs
  }, [blogsCategories])

  //category blogs
  const categoryBlogs = React.useMemo(() => {    
    const blogs : any[] = []
    blogsCategories.reduce((blog1, blog2) => blog1.id > blog2.id ? blog1 : blog2)
      .allblogs.sort((a, b) => {
          return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
        }).map((blog)=> {
          blogs.push(blog)
        })      
    setCategoryName(blogsCategories.reduce((blog1, blog2) => blog1.id > blog2.id ? blog1 : blog2).categoryName)
     
    return blogs
  }, [blogsCategories])
  
  const [isBlogsLoaded, setIsBlogsLoaded] = useState(true)
  const [filteredBlogs, setFilteredBlogs] = useState<any[]>(categoryBlogs)  

  const dateOptions = ["Day", "Week", "Month"]
  const minuteOptions = ["1-5", "5-10", "10-15"]
  const sortByOptions = ["Latest First", "Oldest First"]
  const [sortBy, setSortBy] = useState('Latest First')
  const [filterByMin, setFilterByMin] = useState('')
  const [filterByDate, setFilterByDate] = useState('')
  const [displayDate, setdisplayDate] = useState(true)
  const [displayMins, setdisplayMins] = useState(true)
  const [displayFilter, setDisplayFilter] = useState(false)
  const [sliceMore, setSliceMore] = useState(3)
  const [displayDateIcon, setDisplayDateIcon] = useState(false)
  const [displayMinsIcon, setDisplayMinsIcon] = useState(false)
  const [displaySortByIcon, setDisplaySortByIcon] = useState(false)
      
  const isSmall = useMediaQuery('(min-width:899px)');
  const isMobile = useMediaQuery('(max-width:576px)');

  useEffect(() => {            
    window.scrollTo(0, 0)   
  },[])          

  //filter blogs by date 
  const handleDate = (date: any) => {   
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Filter By", "Click", date.target.value) 
    setFilterByDate(date.target.value)
    setdisplayDate(false)
    setIsBlogsLoaded(false)

    const blogs = blogsCategories.filter((item: any)=> item.categoryName === categoryName)
      
    const filterBlogs = filterBlogsByDate(blogs[0].allblogs, date.target.value)     
    
    if(sortBy === sortByOptions[0]) {
      filterBlogs.sort((a:any,b:any) => {          
        return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      })  
    } else if(sortBy === sortByOptions[1]) {
      filterBlogs.sort((a:any,b:any) => {          
        return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
      })
    }

    if(filterByMin) {      
      const combineFilteredBlogs = filterBlogsByMins(filterBlogs, filterByMin)        
      setFilteredBlogs(combineFilteredBlogs)
    } else {
      setFilteredBlogs(filterBlogs)
    } 
  }    
    
  //filter blogs by read time
  const handleMins = (mins: any) => {
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Filter By", "Click", mins.target.value)
    setFilterByMin(mins.target.value)
    setdisplayMins(false)
    setIsBlogsLoaded(false)

    const blogs = blogsCategories.filter((item: any)=> item.categoryName === categoryName)

    const filterBlogs = filterBlogsByMins(blogs[0].allblogs, mins.target.value)       
    
    if(sortBy === sortByOptions[0]) {
      filterBlogs.sort((a:any,b:any) => {          
        return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      })  
    } else if(sortBy === sortByOptions[1]) {
      filterBlogs.sort((a:any,b:any) => {          
        return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
      })
    }

    if(filterByDate) {
      const combineFilteredBlogs = filterBlogsByDate(filterBlogs, filterByDate)        
      setFilteredBlogs(combineFilteredBlogs)
    } else {
      setFilteredBlogs(filterBlogs)
    }
  }

  const filterBlogsByMins = (blogs: any, mins: string) => {    
    const filterBlogs =  blogs.filter((item: any) => {
      if(mins === minuteOptions[0])
        return item.readTime >= 1 && item.readTime < 5
      if(mins === minuteOptions[1])
        return item.readTime >= 5 && item.readTime < 10
      if(mins === minuteOptions[2])
        return item.readTime >= 10 && item.readTime < 15
      })
    return filterBlogs
  }

  const filterBlogsByDate = (blogs: any, date: string) => {    
    const filterBlogs = blogs.filter((item:any) => {
      if(date === dateOptions[0])
        return new Date(item.publishDate) >= subtractDay()
      if(date === dateOptions[1]) {
        return new Date(item.publishDate) >= subtractWeeks()
      }            
      if(date === dateOptions[2]) {
        const month = subtractMonths(new Date(), 1);            
        return new Date(item.publishDate) >= month
      }                    
    })
    return filterBlogs
  }

  //to find the date of a month ago
  const subtractMonths = (date: Date, months: number) => {
    date.setMonth(date.getMonth() - months);
    date.setHours(0,0,0)
    return date;
  }

  //to find date of a week ago
  const subtractWeeks = () => {
    const now = new Date();
    now.setHours(0,0,0)
    return new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7,
    );
  }

  //one day ago
  const subtractDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    previous.setHours(0,0,0)
    return previous;
  }  
    
  //sort blogs by publish date
  const handleSortBy = (sortBy: any) => {    
    setSortBy(sortBy.target.value)
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Sort By", "Click", sortBy.target.value) 
    setIsBlogsLoaded(false)       

    let sortedBlogs : any[] = []
    if(sortBy.target.value === sortByOptions[0]) {
      sortedBlogs = filteredBlogs.sort((a,b) => {          
        return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      })  
    } else if(sortBy.target.value === sortByOptions[1]) {
      sortedBlogs = filteredBlogs.sort((a,b) => {          
        return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
      })
    }        
    setFilteredBlogs([...sortedBlogs])
  }  

  const handleClearDate = () => {
    const blogs = blogsCategories.filter((item: any)=> item.categoryName === categoryName)
    
    setdisplayDate(true)
    setFilterByDate('')
    let sortedBlogs : any[] = []
    if(sortBy === sortByOptions[0]) {
      sortedBlogs = blogs[0].allblogs.sort((a,b) => {          
        return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      })  
    } else if(sortBy === sortByOptions[1]) {
      sortedBlogs = blogs[0].allblogs.sort((a,b) => {          
        return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
      })
    }
    if(!displayDate && displayMins) {
      setFilteredBlogs(sortedBlogs)      
    } else if(!displayDate) {
      const filterBlogs = filterBlogsByMins(sortedBlogs, filterByMin)
      setFilteredBlogs(filterBlogs)
    }
  }

  const handleClearMins = () => {
    const blogs = blogsCategories.filter((item: any)=> item.categoryName === categoryName)
    setdisplayMins(true)   
    setFilterByMin('')
    let sortedBlogs : any[] = []
    if(sortBy === sortByOptions[0]) {
      sortedBlogs = blogs[0].allblogs.sort((a,b) => {          
        return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      })  
    } else if(sortBy === sortByOptions[1]) {
      sortedBlogs = blogs[0].allblogs.sort((a,b) => {          
        return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
      })
    }
    if(displayDate && !displayMins) {
      setFilteredBlogs(sortedBlogs)      
    } else if(!displayMins) {
      const filterBlogs = filterBlogsByDate(sortedBlogs, filterByDate)
      setFilteredBlogs(filterBlogs)
    }
  }

  const ClearDateIcon = () => (
    <>
      {displayDate ? "" 
                  : <img style={displayDate ? {zIndex: '999', marginRight: '12px'} : {zIndex: '0', marginRight: '12px'}} className="pointer" src={Icons.CloseBlackIcon} alt='Caret Black Color Icon' onClick={handleClearDate}  /> }
    </>    
  )

  const ClearMinsIcon = () => (
    <>
      {displayMins ? ""
                  : <img style={displayMins ? {zIndex: '999', marginRight: '12px'} : {zIndex: '0', marginRight: '12px'}} className="pointer" src={Icons.CloseBlackIcon} alt='Caret Black Color Icon' onClick={handleClearMins}/> }
    </>    
  )

  const handleDateIcon = () => {
    console.log('icon')
    setDisplayDateIcon(true)   
  }

  const handleDateIconClose = () => {
    console.log('icon close')
    setDisplayDateIcon(false)   
  }

  const handleMinsIcon = () => {
    console.log('icon')
    setDisplayMinsIcon(true)   
  }

  const handleMinsIconClose = () => {
    console.log('icon close')
    setDisplayMinsIcon(false)   
  }

  const handleSortByIcon = () => {
    console.log('icon')
    setDisplaySortByIcon(true)   
  }

  const handleSortByIconClose = () => {
    console.log('icon close')
    setDisplaySortByIcon(false)   
  }

  const openBlog = (title: string) => {
    let blogTitle = title.toLowerCase().replace(/ /g,"-").replace(/\?/g, '')
    window.open('/blogs/' + blogTitle, '_blank')    
  }

  //Category Blogs 
  const handleCategoryBlogs = (categoryName: string) => {  
    setSliceMore(3)  
    setSortBy('Latest First')
    setdisplayDate(true)
    setFilterByDate('')
    setdisplayMins(true)   
    setFilterByMin('')
    setCategoryName(categoryName)
    const blogs : any[] = []
    setIsBlogsLoaded(false)
    blogsCategories.filter((blog:any) => (blog.categoryName == categoryName)).map((blogCategory: BlogsCategory) => {    
      blogCategory.allblogs.sort((a, b) => {
          console.log(blogCategory.allblogs)
          return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
        }).map((blog)=> {
          blogs.push(blog)
        })        
    }) 
    setFilteredBlogs(blogs)
  }

  const handleFilter = () => {
    setDisplayFilter(true)
  }

  const handleFilterCross = () => {
    setDisplayFilter(false)    
    setFilterByMin('')
    setFilterByDate('')
    handleClearMins()
    handleClearDate()
    setSortBy('Latest First')
    let sortedBlogs : any[] = []
    sortedBlogs = categoryBlogs.sort((a,b) => {          
      return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
    })
    setFilteredBlogs([...sortedBlogs])
  }

  return (
    <div className="blogs-page-wrapper">
      <Header />
      <h1 className="visuallyHidden">Category Blogs</h1>        

      <div className="blog-data-content content-container">        
        
          <div className="flex al-center monument">
            <p className="category-title partnerLabelColor pointer" onClick={() => {
              history.push('/matter-stream')
              }}>
                MATTER Stream 
                <img style={{ marginLeft: '14px' }} src={Icons.RightChevronIconBlack} alt='Right Caret Black Color Icon' /> 
            </p>
            <p className="category-title-news">Blogs</p>
          </div>              
        
       
        {!isMobile && <div className={filteredBlogs.length > 0 ? "filter-options" : "filter-options-error"}>        
          <Grid container spacing={11} style={{ marginTop: '0' }}>
            <Grid item xs={12} md={8} lg={9} style={{ paddingTop: '0' }}>                
              <div className="filters">
                <p className="filter-by">Filter by:</p>                       
                <div className="filter-dropdown">           
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    className="monument"
                    onChange={handleDate}
                    onOpen={handleDateIcon}      
                    onClose={handleDateIconClose}
                    variant="standard"
                    disableUnderline    
                    startAdornment = {
                      <>
                        <InputAdornment position="start" style={displayDate ? {zIndex: '999'} : {zIndex: '0'}}>    
                          {displayDate && <><p className='adornmentDate'>Date</p>
                            {/* <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /> */}
                            {displayDateIcon ? <img src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' /> :
                              <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />}
                          </>
                          }                                                    
                        </InputAdornment>
                      </>
                    }
                    IconComponent={ClearDateIcon}  
                    value={filterByDate}                  
                  >                
                    {dateOptions.map((option) => (
                      <MenuItem                    
                        key={option}
                        value={option}
                        className="dropdown-options"
                      >
                        <p className="small black">1 {option}</p>                    
                      </MenuItem>
                    ))}
                  </Select>   
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    className="monument"
                    onChange={handleMins}   
                    onOpen={handleMinsIcon}      
                    onClose={handleMinsIconClose}             
                    variant="standard"
                    disableUnderline    
                    startAdornment={
                      <>
                        <InputAdornment position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '0'}}>    
                          {displayMins && <><p className='adornmentReadTime'>Read Time</p>
                            {displayMinsIcon ? <img src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' /> :
                              <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />}
                          </>}
                        </InputAdornment>
                      </>
                    }
                    IconComponent={ClearMinsIcon}        
                    value={filterByMin}
                  >                
                    {minuteOptions.map((option) => (
                      <MenuItem                    
                        key={option}
                        value={option}
                        className="dropdown-options"
                      >
                        <p className="small black">{option} mins</p>
                      </MenuItem>
                    ))}
                  </Select>                        
                </div>                     
              </div>                                   
            </Grid>
            <Grid item={isSmall} xs={12} md={4} lg={3} className="sort-by-section" style={{ paddingTop: '0' }}>       
              <div className="filters">
                <p className="sort-by">Sort by:</p>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  className="monument"
                  style={!isMobile ? { height: "46px" } : {}}
                  onChange={handleSortBy}    
                  onOpen={handleSortByIcon}      
                  onClose={handleSortByIconClose}            
                  variant="standard"
                  //defaultValue={sortByOptions[0]}
                  value={sortBy}
                  disableUnderline           
                  startAdornment={
                    <>
                      <InputAdornment className="sort-by-icon" position="start" style={displayDate ? {zIndex: '999'} : {zIndex: '999'}}>                            
                        {/* <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /> */}
                        {displaySortByIcon ? <img className="caret-icon" src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' /> :
                              <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />}
                      </InputAdornment>
                    </>
                  }  
                >                
                  {sortByOptions.map((option) => (
                    <MenuItem                    
                      key={option}
                      value={option}
                      className="dropdown-options"
                    >
                      <p className="small black">{option}</p>
                    </MenuItem>
                  ))}
                </Select>   
              </div>     
            </Grid>          
          </Grid>
        </div>} 

        {isMobile && <Grid container spacing={11} style={{ marginTop: '28px', marginBottom: '32px' }}>          
          <Grid item xs={4} md={8} lg={5} style={{ paddingTop: '0' }}>                
            {(filterByDate || filterByMin) ? <img className="caret-icon" src={Icons.FilterIconRed} alt='Filter Icon' onClick={handleFilter}/> 
            : <img className="caret-icon" src={Icons.FilterIcon} alt='Filter Icon' onClick={handleFilter}/>}
          </Grid>
        </Grid>}

        {isMobile && <Dialog
            //maxWidth={"md"}
            //aria-labelledby="customized-dialog-title"
            open={displayFilter}
            //className="verify-email confirmation-dialog"
            className="matter-dialog"
            fullWidth
            style={isMobile ? {top: '0', bottom: 'auto'} : {}}
          >          
            <DialogContent style={{ height: '100vh', display: "flex", flexDirection: "column", justifyContent: "space-between" }}>                                  
              <div className={filteredBlogs.length > 0 ? "filter-options" : "filter-options-error"}>        
                <div className="flex js-end">                
                  <img className="caret-icon" src={Icons.CloseIconGray} alt='Filter Icon' onClick={handleFilterCross}/>
                </div>
                  <Grid container spacing={11} style={{ marginTop: '0' }}>                    
                    <Grid item xs={12} md={4} lg={7} className="sort-by-section" style={{ paddingTop: '0', paddingLeft: '0' }}>                       
                        <div className="filters">
                          <p className="filter-by">Filter by:</p>                       
                          <div className="mb-24">           
                            <Select
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              className="monument"
                              onChange={handleDate}
                              onOpen={handleDateIcon}      
                              onClose={handleDateIconClose}
                              variant="standard"
                              disableUnderline    
                              startAdornment = {
                                <>
                                  <InputAdornment position="start" style={displayDate ? {zIndex: '999'} : {zIndex: '0'}}>    
                                    {displayDate && <div className="flex js-between w-100"><p className='adornmentDate'>Date</p>
                                      {displayDateIcon ? <img src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> :
                                        <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} />}
                                    </div>
                                    }                                                    
                                  </InputAdornment>
                                </>
                              }
                              IconComponent={ClearDateIcon}                    
                              style={{ marginBottom: '20px'}}
                              value={filterByDate}
                            >                
                              {dateOptions.map((option) => (
                                <MenuItem                    
                                  key={option}
                                  value={option}
                                  className="dropdown-options"                                  
                                >
                                  <p className="small black" style={isMobile ? {fontSize: '14px'} : {}}>1 {option}</p>                    
                                </MenuItem>
                              ))}
                            </Select>   
                            <Select
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              className="monument"
                              onChange={handleMins}  
                              onOpen={handleMinsIcon}      
                              onClose={handleMinsIconClose}              
                              variant="standard"
                              disableUnderline    
                              startAdornment={
                                <>
                                  <InputAdornment position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '0'}}>    
                                    {displayMins && <div className="flex js-between w-100"><p className='adornmentReadTime'>Read Time</p>
                                      {/* <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> */}
                                      {displayMinsIcon ? <img src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> :
                                        <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} />}
                                    </div>}
                                  </InputAdornment>
                                </>
                              }
                              IconComponent={ClearMinsIcon}      
                              value={filterByMin}  
                            >                
                              {minuteOptions.map((option) => (
                                <MenuItem                    
                                  key={option}
                                  value={option}
                                  className="dropdown-options"
                                >
                                  <p className="small black" style={isMobile ? {fontSize: '14px'} : {}}>{option} mins</p>
                                </MenuItem>
                              ))}
                            </Select>                        
                          </div>                     
                        </div>                                         
                        <div className="filters">
                          <p className="sort-by">Sort by:</p>
                          <Select
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            className="monument"
                            style={!isMobile ? { height: "46px" } : {}}
                            onChange={handleSortBy}
                            onOpen={handleSortByIcon}      
                            onClose={handleSortByIconClose}                
                            variant="standard"
                            //defaultValue={sortByOptions[0]}
                            value={sortBy}
                            disableUnderline           
                            startAdornment={
                              <>
                                <InputAdornment className="sort-by-icon" position="start" style={displayMins ? {zIndex: '999'} : {zIndex: '999'}}>                            
                                <div className="flex js-end w-100">
                                  {/* <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> */}
                                  {displaySortByIcon ? <img className="caret-icon" src={Icons.UpCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} /> :
                                      <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' width={12} height={12} />}
                                </div>                                  
                                </InputAdornment>
                              </>
                            }  
                          >                
                            {sortByOptions.map((option) => (
                              <MenuItem                    
                                key={option}
                                value={option}
                                className="dropdown-options"
                              >
                                <p className="adornmentReadTime black" style={isMobile ? {fontSize: '14px'} : {}}>{option}</p>
                              </MenuItem>
                            ))}
                          </Select>   
                        </div>                       
                    </Grid>          
                  </Grid>
                </div>  
                <button                  
                  onClick={() => {
                    setDisplayFilter(false)
                  }}
                  className={"btn-gradiant-border large mt-24 mt-xs-16 btn-white w-100"}
                  style={{ boxShadow: 'none', marginBottom: '140px', lineHeight: '1.5', justifyContent: 'center'}}
                >
                  Show {(filterByDate || filterByMin) && filteredBlogs.length} Results
                </button>            
            </DialogContent>
        </Dialog>}

        {/* category and blogs */}
        {filteredBlogs.length > 0 && <div className="flex blog-section">
          {blogsCategories.map((blogCategory: BlogsCategory, index) =>           
            <p className={"category-sub-title small subText pointer " + (blogCategory.categoryName === categoryName && "boldName")} onClick={() => {                    
                handleCategoryBlogs(blogCategory.categoryName)
            }}>
              {blogCategory.categoryName}                
            </p>                                                                                      
          )}        
        </div>}


        {/* display filtered and initial load blogs */}
        {filteredBlogs.length > 0 ? 
          (isMobile ? <Grid container spacing={{xs: 1.5, sm: 3}} rowSpacing={{ md: 10 }} style={{ marginTop: '0' }}> 
            {(isBlogsLoaded ? categoryBlogs : filteredBlogs).slice(0, sliceMore).map((item, index)=>                 
              <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', paddingBottom: '24px' }}>
                <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} 
                  onClick={()=> { 
                    openBlog(item.title)
                    tagMethodGTM(categoryName, "Matter Blog " + categoryName, "Click", item.title) 
                    }}>
                <div className="monument pointer blog-posts">
                  <ProgressiveImage alt="Matter Category Blogs"                    
                    className="more-posts-blog-img" 
                    imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                    previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                  
                  <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                  <span className="dot"></span>                  
                  <p className="date ml-8">{item.readTime} min read</p>
                  <p className="blog-data-title mt-8">
                        {item.title}
                  </p>                
                  <p className="blog-description">
                    {item.blogIntroduction} 
                  </p>
                </div>
                </Link>                
              </Grid>                 
            )}  
            {((sliceMore < (isBlogsLoaded ? categoryBlogs : filteredBlogs).length) && isMobile) && 
            <div style={{ paddingLeft: "12px" }}>
                <p className="more-posts primary pointer" onClick={() => {
                  setSliceMore(sliceMore + 3)
                }}>
                  <span className="underline pb-2">Load More</span>
                  <img
                    className="ml-4"
                    src={Icons.RightChevronIconBlue}
                    alt="Right Chevron Icon Blue"
                  />
                </p>              
            </div>}         
          </Grid> :
          <Grid container spacing={{xs: 1.5, sm: 3}} rowSpacing={{ md: 10 }} style={{ marginTop: '0' }}> 
            {(isBlogsLoaded ? categoryBlogs : filteredBlogs).map((item, index)=>                 
              <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', paddingBottom: '48px' }}>
                <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} 
                  onClick={()=> { 
                    openBlog(item.title)
                    tagMethodGTM(categoryName, "Matter Blog " + categoryName, "Click", item.title) 
                    }}>
                <div className="monument pointer blog-posts">
                  <ProgressiveImage alt="Matter Category Blogs"                    
                    className="more-posts-blog-img" 
                    imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                    previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                  
                  <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                  <span className="dot"></span>                  
                  <p className="date ml-8">{item.readTime} min read</p>
                  <p className="blog-data-title mt-8">
                        {item.title}
                  </p>                
                  <p className="blog-description">
                    {item.blogIntroduction} 
                  </p>
                </div>
                </Link>                
              </Grid>                 
            )}                       
          </Grid>) :<>
          {/* // display recent blogs if no blogs found after filter */}
          <div className="recent-blogs">
            <p>Opps, we couldn’t find a blog try adjusting your filter.</p>
          </div>
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
            <h5 className="monument mb-16">Latest Blogs</h5>
            {isSmall ? <Grid container spacing={{xs: 1.5, sm: 3}}>
              {blogsAllCategories.slice(0,3).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    openBlog(item.title)              
                    //tagMethodGTM(blogCategory.categoryName, "Matter Blog " + blogCategory.categoryName, "Click", item.title)
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {
                    
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                    
                    <p className="date ml-8">{item.readTime} min read</p>
                    <p className="blog-data-title mt-8">
                      {item.title}
                    </p>                
                    <p className="blog-description">
                      {item.blogIntroduction} 
                    </p>
                  </div>
                  </Link>
                </Grid>
              )}               
            </Grid>
            :
              <Swiper
                spaceBetween={24}
                width={300}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {blogsAllCategories.slice(0,3).map((item, index)=>     
                  <SwiperSlide>
                    <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                      openBlog(item.title)              
                      //tagMethodGTM(blogCategory.categoryName, "Matter Blog " + blogCategory.categoryName, "Click", item.title)
                    }}>
                      <div className="blog-posts monument pointer" onClick={() => {
                        
                      }
                        }>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="more-posts-blog-img" 
                          imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                          previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                    
                        <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                        <span className="dot"></span>                    
                        <p className="date ml-8">{item.readTime} min read</p>
                        <p className="blog-data-title mt-8">
                          {item.title}
                        </p>                
                        <p className="blog-description">
                          {item.blogIntroduction} 
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                )}
              </Swiper>            
            }
          </div>
          </>}
          
      </div>        
      <Footer />
    </div>    
  )
}

export default CategoryBlogs
